/* Notepad.css */

body {
  font-family: Arial, sans-serif;
  background-color: #f4f6f8;
}

.notepad {
  margin: 20px auto;
  padding: 20px;
  max-width: 800px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #1f2937;
  padding: 10px;
  border-radius: 5px;
}

.menu button {
  background: none;
  color: #ffffff;
  border: none;
  padding: 8px;
  font-size: 16px;
  cursor: pointer;
}

.menu button:hover {
  background-color: #374151;
  border-radius: 3px;
}

.dropdown {
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 5px 0;
  margin-top: 8px;
  z-index: 1000;
}

.dropdown button {
  width: 100%;
  padding: 8px;
  text-align: left;
  background: none;
  border: none;
  font-size: 14px;
  color: #333;
  cursor: pointer;
}

.dropdown button:hover {
  background-color: #e5e7eb;
}

textarea {
  width: 100%;
  height: 400px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  color: #333;
  resize: none;
  outline: none;
}

.font-selector,
.share-button {
  margin-left: 10px;
  padding: 8px 12px;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 14px;
  cursor: pointer;
}

.share-button {
  background-color: #3b82f6;
  color: #fff;
  border: none;
}

.share-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  z-index: 2000;
}

.share-popup p {
  margin: 0;
  font-weight: bold;
}

.share-popup input {
  width: 100%;
  margin: 10px 0;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.share-popup button {
  padding: 8px 12px;
  background-color: #3b82f6;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.share-popup button:hover {
  background-color: #2563eb;
}

/* Assuming your notepad box has a max-width of 800px */
.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f0f4f8; /* Light background color for contrast */
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
}

.section-title {
  font-size: 1.8em;
  font-weight: bold;
  color: #333;
  margin-top: 20px;
}

.section-content {
  font-size: 1.1em;
  line-height: 1.6;
  color: #555;
}

.section-features ul {
  list-style-type: disc;
  padding-left: 20px;
}
